import React from 'react';
import './imprint.component.sass';

class Imprint extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <h1>Imprint works!</h1>
    );
  }
}

export default Imprint;
import React from 'react';
import './footer.component.sass';

class Footer extends React.Component {

  render() {
    return (
      <footer className="footer">
        <p>footer</p>
      </footer>
    )
  }
}

export default Footer;